import React from "react";
import { Header } from "src/components/header";

export const Intro = (props: any) => {
  return (
    <div className="intro-section">
      <div className="wrapper flex-row center-center">
        <div className="intro-img">
          <img src="/assets/intro.gif" alt="intro-img" />
        </div>

        <div className='intro-blk'>
          <Header header="ZEETOX NFT" subHeader="by Zeetox" />

          <p>
            Each ZEETOX NFT represents ownership of a specific Cannabis Pot with a unique ID from our farm. It's also a ZEETOX membership which allows you to join our VIP tour and have exclusive access to our ZEETOX metaverse and purchase your own virtual farmland in the near future. Each ZEETOX NFT holder will receive a portion of the income from the cannabis you own every year. In addition, ZEETOX NFT will be listed and traded on Opensea.
          </p>

          <img src="/assets/logo_zeetox_R2.png" alt='alt-logo' />
        </div>
      </div>
    </div>
  );
};
