import React from "react";

export const Footer = (props: any) => {

  return (
      <div className="footer">
        <p>@2022 by ZEETOX</p>
      </div>
  );
};
