import React from "react";
import { Header } from "src/components/header";

export const Cert = (props: any) => {
  return (
    <div className="cert-section">
      <div className="wrapper flex-row center-center">
        <div className="cert-img">
          <img src="/assets/Certificate.png" alt="cert-img" />
        </div>

        <div className='cert-blk'>
          <Header header="Licenses Obtained" subHeader="100% Legal" />

          <ul>
            <li>Import of Seeds</li>
            <li>Cultivation (plantation license)</li>
            <li>Processing (manufacturing license)</li>
            <li>Sales (distribution license, including export)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
